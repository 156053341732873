<template>
  <div class="activity_item_container">
    <div class="activity-video-item" @click="itemClickHandler">
      <div class="open">
        <img src="/static/img/mobile/open.png" />
      </div>
      <img class="cover" :src="item[prefix + 'cover']" />
      <div class="mask">
        <div class="title">{{item[prefix + "title"]}}</div>
        <div class="info">
          <div class="time">{{dateFormat(item.issue_time, "y /m / d")}}</div>
          <div class="look_num">
            <img src="/static/img/mobile/icon-eye.png">
            {{item.look_num}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    prefix() {
      return ["open", "end"][this.type] + "_";
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      default: 0,
    },
  },
  methods: {
    itemClickHandler() {
      this.$api("/official/page/inc_look_num", { id: this.item.id });
      this.item.look_num++;
      this.showFullScreenVideo(this.item[this.prefix + "video"]);
    },
  },
  watch: {
    item(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-video-item {
  cursor: pointer;

  width: 100%;
  display: flex;
  justify-content: center;

  position: relative;

  border-radius: 46px;
  overflow: hidden;

  margin-top: 68px;
  &:first-child {
    margin-top: 81px;
  }

  > .cover {
    width: 1342px;
    height: 803px;
    border-radius: 46px;
  }

  > .open {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 115px;
      height: 115px;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.56);
    padding: 31px 38px 27px 38px;

    > .title {
      bottom: 0;
      font-size: 58px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 81px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > .info {
      margin-top: 8px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 69px;

      display: flex;
      justify-content: space-between;
    }
  }
}
</style>