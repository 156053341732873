<template>
  <div class="mobile_activity_container">
    <div class="top">
      <div class="title">
        <div class="main-title">大赛<font>/CONTEST</font>
        </div>
        <div class="sub-title">年度盛会 卫视首播</div>
      </div>
      <div class="block_center">
        <div class="tabs">
          <div :class="{actived:tabIdx == 0}" @click="changeTabIdx(0)">开幕式</div>
          <div :class="{actived:tabIdx == 1}" @click="changeTabIdx(1)">特金奖</div>
        </div>
      </div>
      <div class="content">
        {{$route.query.content}}
      </div>
    </div>

    <div :class="{activityList:true, show:showActivityList}">
      <ActivityItem v-for="(activity,key) in activityList" :key="key" :item="activity" :type="tabIdx" />
    </div>
  </div>
</template>

<script>
import ActivityItem from "@/components/mobile/activity_item.vue";
export default {
  components: {
    ActivityItem,
  },
  data() {
    return {
      tabIdx: 0,
      activityList: [],
      showActivityList: true,
    };
  },
  created() {
    this.$api("/official/page/mobile_activity").then(({ data }) => {
      this.activityList = data;
    });
  },
  methods: {
    changeTabIdx(idx) {
      this.tabIdx = idx;

      this.showActivityList = false;
      setTimeout(() => {
        this.showActivityList = true;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
.block_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile_activity_container {
  margin: 0 50px;
  margin-bottom: 300px;

  > .top {
    background: linear-gradient(180deg, #ffffff 0%, #ffebe0 100%);
    border-radius: 54px;

    > .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 77px;
        font-size: 73px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 100px;
        font {
          color: $font-color-orange;
        }
      }
      .sub-title {
        margin-top: 19px;
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 76px;
      }
    }

    .tabs {
      margin-top: 96px;

      width: 845px;
      height: 134px;
      border-radius: 68px;
      border: 2px solid #ff6f1b;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff6f1b;
      // line-height: 73px;

      display: flex;
      justify-content: space-between;

      position: relative;

      div {
        flex: 1;
        border-radius: 68px;
        border: 2px solid #ff6f1b;
        padding: 31px 0;

        display: flex;
        align-items: center;
        justify-content: center;

        &.actived {
          background-color: #ff6f1b;
          color: white;
        }

        &:not(.actived) {
          border: 2px solid white;
        }
      }
    }

    > .content {
      margin: 77px 50px 0 50px;
      padding-bottom: 77px;

      font-size: 58px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 104px;
    }
  }

  .activityList {
    opacity: 0;

    &.show {
      transition: 0.5s;
      opacity: 1;
    }
  }
}
</style>